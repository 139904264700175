import AuthGuard from '../core/guard/AuthGuard';
import RoleGuard from '../core/guard/RoleGuard';
import Layout from '../layout/Layout';
import Dashboard from '../pages/dashboard/Dashboard';
import ExportPdf from '../pages/export-pdf/ExportPdf';
import GoodsMgt from '../pages/goods-mgt/GoodsMgt';
import InvDetail from '../pages/inv-mgt/InvDetail';
import InvMgt from '../pages/inv-mgt/InvMgt';
import AddOrder from '../pages/order-mgt/AddOrder';
import EditOrder from '../pages/order-mgt/EditOrder';
import ExportTracking from '../pages/order-mgt/ExportTracking';
import OrderDetail from '../pages/order-mgt/OrderDetail';
import OrderMgt from '../pages/order-mgt/OrderMgt';
import Setting from '../pages/setting/Setting';
import UserMgt from '../pages/user-mgt/UserMgt';
import VehicleDetail from '../pages/vehicle-mgt/VehicleDetail';
import VehicleMgt from '../pages/vehicle-mgt/VehicleMgt';

export const layoutRouter: any = {
  path: '/',
  element: (
    <AuthGuard>
      <Layout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/dashboard',
      element: (
        <RoleGuard>
          <Dashboard />
        </RoleGuard>
      )
    },
    {
      path: '/order-mgt',
      element: (
        <RoleGuard>
          <OrderMgt />
        </RoleGuard>
      )
    },
    { path: '/order-mgt/detail/:id', element: <OrderDetail /> },
    { path: '/order-mgt/export/:id', element: <ExportTracking /> },
    {
      path: '/user-mgt',
      element: (
        <RoleGuard>
          <UserMgt />
        </RoleGuard>
      )
    },
    { path: '/goods-mgt', element: <GoodsMgt /> },
    {
      path: '/add-order',
      element: (
        <RoleGuard>
          <AddOrder />
        </RoleGuard>
      )
    },
    { path: '/edit-order/:orderId', element: <EditOrder /> },
    {
      path: '/settings',
      element: (
        <RoleGuard>
          <Setting />
        </RoleGuard>
      )
    },
    {
      path: '/inv-mgt',
      element: (
        <RoleGuard>
          <InvMgt />
        </RoleGuard>
      )
    },
    { path: '/inv-mgt/:id', element: <InvDetail /> },
    {
      path: '/vehicles',
      element: (
        <RoleGuard>
          <VehicleMgt />
        </RoleGuard>
      )
    },
    { path: '/vehicles/:id', element: <VehicleDetail /> },
    {
      path: '/export-pdf',
      element: (
        <RoleGuard>
          <ExportPdf />
        </RoleGuard>
      )
    },
  ]
};
