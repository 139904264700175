import { useState } from 'react';
import UploadFile from '../../../components/ui/UploadFile';
import { OrderService } from '../../../core/services/order.service';
import { useAppDispatch } from '../../../libs/hook';
import { hideSpinner, showSpinner } from '../../../libs/features/spinnerSlice';
import { toast } from 'react-toastify';
import { FileService } from '../../../core/services/common/file.service';

type ImportFileModalProps = {
  onSubmit?: (event: any) => void;
  onClose?: (event?: any) => void;
};

export default function ImportFileModal(props: ImportFileModalProps) {
  const dispatch = useAppDispatch();
  const [images, setImages] = useState<any[]>([]);
  const [excelFile, setExcelFile] = useState<any>();

  const onChangeExcel = (event: any) => {
    setExcelFile(event.filesToAdd[0]);
  };

  const onChangeImage = (event: any) => {
    setImages(event.filesToAdd);
  };

  const onSubmitForm = () => {
    if (!excelFile) {
      toast.error('Chưa có file excel');
      return;
    }
    if (!images || images?.length <= 0) {
      toast.error('Chưa có hình ảnh');
      return;
    }
    dispatch(showSpinner());
    OrderService.createOrUpdateFormFile(excelFile, images)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        if (!res.data.status) {
          toast.error(res.data.message);
          return;
        }
        props.onClose?.(true);
      });
  };

  const downloadFile = () => {
    dispatch(showSpinner());
    FileService.downloadImportTemplate().finally(() => {
      setTimeout(() => {
        dispatch(hideSpinner());
      }, 500);
    }).then((res) => {
      // Tạo một URL từ blob
      const url = window.URL.createObjectURL(new Blob([res.data]));

      const link: any = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'mau-nhap-kho.xlsx'); // Đặt tên file Excel

      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
    });
  };

  return (
    <>
      <div className="modal-body">
        <div className="row">
          <div className="col-12 py-3">
            <div className="text-heading mb-2">
              Tệp Excel <span className="badge rounded-pill bg-primary cursor-pointer" onClick={downloadFile}>Tải file mẫu</span>
            </div>
            <UploadFile onChange={onChangeExcel} accept=".xlsx, .xls" multiple={false}></UploadFile>
          </div>
          <div className="col-12 py-3">
            <div className="text-heading mb-2">
              Hình ảnh các đơn hàng <span className="badge rounded-pill bg-primary">Mẫu tên ảnh: Mã đơn hàng_stt.jpg</span>
            </div>
            <UploadFile onChange={onChangeImage} accept="image/*" multiple></UploadFile>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-outline-secondary waves-effect" onClick={() => props.onClose?.(false)}>
          Hủy
        </button>
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={onSubmitForm}>
          Thêm
        </button>
      </div>
    </>
  );
}
