import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { MENUS } from '../../layout/menu';
import { useAppSelector } from '../../libs/hook';
import { userInfoSelector } from '../../libs/selectors/userSelector';

export default function RoleGuard(props: any) {
  const currentUser = useAppSelector(userInfoSelector);
  const location = useLocation();
  const pathname = location.pathname;
  // const [hasPerm, setHasPerm] = useState<boolean>(true);
  const [menus, setMenus] = useState<any[]>([]);
  const [auth, setAuth] = useState<boolean>(true);

  useEffect(() => {
    if (currentUser.userUid) {
      let menuTemps = MENUS;
      menuTemps = menuTemps.filter((menu: any) => menu.roles.includes(currentUser.role?.roleId));
      // checkRole(menuTemps);
      setMenus(menus);
    }
  }, [currentUser]);

  const checkRole = (menus: any[]) => {
    debugger
    if (menus.length > 0) {
      let permission = menus.some((menu: any) => menu.url == pathname);
      // setHasPerm(permission);
      return permission;
    }
    // setHasPerm(false);
    return false;
  };
  // return checkRole(menus) ? props.children : <Navigate to="/not-permission" replace></Navigate>;
  return props.children;
}
