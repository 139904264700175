import FormFloating from '../../components/ui/form/FormFloating';
import FormFloatingTextArea from '../../components/ui/form/FormFloatingTextArea';
import FormFloatingSelect from '../../components/ui/form/FormFloatingSelect';
import { UserService } from '../../core/services/user.service';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../libs/hook';
import { hideSpinner, showSpinner } from '../../libs/features/spinnerSlice';
import { OrderService } from '../../core/services/order.service';
import { useNavigate, useParams } from 'react-router-dom';
import UploadFile from '../../components/ui/UploadFile';
import { FileService } from '../../core/services/common/file.service';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormFloatingNumber from '../../components/ui/form/FormFloatingNumber';
import NumberFormat from '../../components/common/NumberFormat';

const schema = yup
  .object({
    orderId: yup.string().required('Mã đơn hàng là bắt buộc'),
    // customerId: yup.string().required('Khách hàng là bắt buộc'),
    // sellerId: yup.string().required('Nhân viên bán là bắt buộc'),
    // recipientPhoneNumber: yup.string().length(10, 'Số điện thoại có gồm 10 số').required('Số điện thoại là bắt buộc'),
    // address: yup.string().length(2, 'Điểm đến chỉ gồm 2 kí tự').required('Điểm đến là bắt buộc'),
    note: yup.string(),
    // productType: yup.string().required('Loại hàng hóa là bắt buộc'),
    numberParcel: yup.string().required('Số kiện là bắt buộc')
    // size: yup.number(),
    // weight: yup.number()
  })
  .required();

export default function EditOrder() {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm<any>({
    resolver: yupResolver(schema)
  });

  const { orderId }: any = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [sellers, setSellers] = useState<any[]>([]);
  const [customers, setCustomers] = useState<any[]>([]);
  const [filesToAdd, setFilesToAdd] = useState<any>([]);
  const [orderDetail, setOrderDetail] = useState<any>({});
  const [filesToDelete, setFilesToDelete] = useState<any[]>([]);
  const [images, setImages] = useState<any[]>([]);
  const [trackingImport, setTrackingImport] = useState<any>({});
  const [orderRequest, setOrderRequest] = useState<any>({});
  // const [customerId, setCustomerId] = useState<any>();
  // const [recipientPhoneNumber, setRecipientPhoneNumber] = useState<any>();

  useEffect(() => {
    if (orderId) {
      getOrder();
    }
  }, [orderId]);

  const getOrder = () => {
    dispatch(showSpinner());
    OrderService.getOrderById(orderId)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        setOrderDetail(res.data.responseData);
        setFormValue(res.data.responseData);
        setTrackingImport(res.data.responseData?.trackingImages);
      });
  };

  const setFormValue = (orderDetail: any) => {
    setImages(orderDetail.trackings[0].trackingImages);
    setValue('orderId', orderDetail.orderId);
    setValue('customerId', orderDetail.customerId);
    setValue('sellerId', orderDetail.sellerId);
    setValue('recipientPhoneNumber', orderDetail.recipientPhoneNumber);
    setValue('address', orderDetail.address);
    setValue('note', orderDetail.note);
    setValue('productType', orderDetail.productType);
    setValue('numberParcel', orderDetail.numberParcel);
    setValue('size', orderDetail.size);
    setValue('weight', orderDetail.weight);
    setValue('piece', orderDetail.piece);

    setValue('shippingFee', orderDetail.shippingFee);
    setValue('domesticFee', orderDetail.domesticFee);
    setValue('towingFee', orderDetail.towingFee);
    setValue('forkliftFee', orderDetail.forkliftFee);
    setValue('woodenFee', orderDetail.woodenFee);
    setValue('payOnHehalf', orderDetail.payOnHehalf);
    setValue('totalFee', orderDetail.totalFee);
    setValue('payAmount', orderDetail.payAmount);

    // setCustomerId(orderDetail.customerId);
    // setRecipientPhoneNumber(orderDetail.recipientPhoneNumber);
    orderRequest.shippingFee = orderDetail.shippingFee;
    orderRequest.domesticFee = orderDetail.domesticFee;
    orderRequest.towingFee = orderDetail.towingFee;
    orderRequest.forkliftFee = orderDetail.forkliftFee;
    orderRequest.woodenFee = orderDetail.woodenFee;
    orderRequest.payOnHehalf = orderDetail.payOnHehalf;
    orderRequest.totalFee = orderDetail.totalFee;
    orderRequest.payAmount = orderDetail.payAmount;
    setOrderRequest({ ...orderRequest });
  };

  useEffect(() => {
    dispatch(showSpinner());
    initData();
    setTimeout(() => {
      dispatch(hideSpinner());
    }, 500);
  }, []);

  // useEffect(() => {
  //   if (!customerId) {
  //     setValue('recipientPhoneNumber', '');
  //     setRecipientPhoneNumber('');
  //     return;
  //   }
  //   const timeout = setTimeout(() => {
  //     UserService.getCustomerByUserName(customerId).then((res) => {
  //       if (res.data.status) {
  //         let userTemp = res.data.responseData;
  //         setValue('recipientPhoneNumber', userTemp.phoneNumber);
  //         clearErrors('recipientPhoneNumber');
  //         setRecipientPhoneNumber(userTemp.phoneNumber);
  //       } else {
  //         UserService.getCustomerByPhoneNumber(recipientPhoneNumber).then((res) => {
  //           if (res.data.status) {
  //             setValue('recipientPhoneNumber', '');
  //             setRecipientPhoneNumber('');
  //           }
  //         });
  //       }
  //     });
  //   }, 500);

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [customerId]);

  const initData = async () => {
    await Promise.all([getSellers(), getCustomers()]);
  };

  const getSellers = async () => {
    await UserService.getAllSeller().then((res) => {
      setSellers(res.data);
    });
  };

  const getCustomers = async () => {
    await UserService.getAllCustomer().then((res) => {
      setCustomers(res.data);
    });
  };

  const onCancel = () => {
    navigate(`/order-mgt/detail/${orderId}`);
  };

  const onSubmit = async (data: any) => {
    if (filesToDelete?.length == trackingImport?.trackingImages?.length && filesToAdd.length <= 0) {
      toast.error('Hình ảnh còn thiếu');
      return;
    }

    dispatch(showSpinner());
    if (filesToAdd.length > 0) {
      FileService.multiFileUpload(filesToAdd).then(
        (res) => {
          if (!res.data.status) {
            toast.error('Đã xảy ra lỗi trong quá trình upload file, vui lòng thử lại');
            return;
          }
          data.filesToAdd = res.data.responseData;
          handleUpdateOrder(data);
        },
        (error) => {
          dispatch(hideSpinner());
        }
      );
    } else {
      handleUpdateOrder(data);
    }
  };

  const handleUpdateOrder = (data: any) => {
    data.orderId = orderDetail.orderId;
    data.trackId = orderDetail.trackings[0].trackId;
    data.filesToDelete = filesToDelete;
    OrderService.editOrder(data)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        if (res.data.status) {
          navigate(`/order-mgt/detail/${orderId}`);
        }
      });
  };

  const onChangeSellerOrCustomer = (event: any, field: string) => {
    setValue(field, event?.username);
  };

  const onChangeFile = (event: any) => {
    setFilesToAdd(event.filesToAdd);
    setFilesToDelete(event.filesToDelete);
  };

  const onChangeValue = (event: any) => {
    let valueTemp = event.target.value + "";
    setValue(event.target.name, valueTemp.trim());
    orderRequest[event.target.name] = valueTemp.trim();
    const sum = Number(orderRequest.domesticFee || 0) + Number(orderRequest.towingFee || 0) + Number(orderRequest.forkliftFee || 0) + Number(orderRequest.shippingFee || 0) + Number(orderRequest.woodenFee || 0) + Number(orderRequest.payOnHehalf || 0);
    orderRequest.totalFee = sum;

    setOrderRequest({ ...orderRequest });
  };

  // const onChangeCustomerId = (event: any) => {
  //   setCustomerId(event.target.value);
  //   setValue('customerId', event.target.value);
  //   clearErrors('customerId');
  // };

  // const onChangePhoneNumber = (event: any) => {
  //   setRecipientPhoneNumber(event.target.value);
  //   setValue('recipientPhoneNumber', event.target.value);
  //   clearErrors('recipientPhoneNumber');
  // };

  return (
    <>
      <div className="app-ecommerce">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-6 row-gap-4">
          <div className="d-flex flex-column justify-content-center">
            <h4 className="mb-1">Cập nhật thông tin đơn hàng</h4>
            {/* <p className="mb-0">Orders placed across your store</p> */}
          </div>
          <div className="d-flex align-content-center flex-wrap gap-4">
            <button type="button" className="btn btn-outline-secondary" onClick={onCancel}>
              Hủy
            </button>
            <button type="button" className="btn btn-primary" onClick={handleSubmit(onSubmit)}>
              Cập nhật
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-7">
            <div className="card mb-6">
              <div className="card-header">
                <h5 className="card-tile mb-0">Thông tin chung</h5>
              </div>
              <div className="card-body">
                <div className="row mb-5 gx-5">
                  <div className="col">
                    <FormFloating type="text" defaultValue={orderId || ''} label="Mã đơn hàng" placeholder="Mã đơn hàng" disabled></FormFloating>
                  </div>
                </div>
                {/* <div className="row mb-5 gx-5">
                  <div className="col">
                    <FormFloating
                      type="text"
                      label="Mã khách hàng"
                      placeholder="Mã khách hàng"
                      // {...register('customerId')}
                      isError={errors.customerId ? true : false}
                      errMsg={errors.customerId?.message}
                      onChange={onChangeCustomerId}
                      maxLength={10}
                      value={customerId || ''}
                      name="customerId"
                    ></FormFloating>
                  </div>
                  <div className="col">
                    <FormFloatingSelect
                      label="Nhân viên bán hàng"
                      sources={sellers}
                      bindLabel="fullName"
                      bindValue="username"
                      {...register('sellerId')}
                      onChange={(event) => onChangeSellerOrCustomer(event, 'sellerId')}
                      isError={errors.sellerId ? true : false}
                      errMsg={errors.sellerId?.message}
                      defaultValue={orderDetail.sellerId}
                    ></FormFloatingSelect>
                  </div>
                </div>

                <div className="row mb-5 gx-5">
                  <div className="col">
                    <FormFloating
                      type="number"
                      label="SĐT người nhận"
                      placeholder="SĐT người nhận"
                      // {...register('recipientPhoneNumber')}
                      // isError={errors.recipientPhoneNumber ? true : false}
                      // errMsg={errors.recipientPhoneNumber?.message}
                      maxLength={10}
                      onChange={onChangePhoneNumber}
                      name="recipientPhoneNumber"
                      value={recipientPhoneNumber || ''}
                    ></FormFloating>
                  </div>
                </div> */}

                <FormFloatingTextArea className="mb-5" label="Chú thích" placeholder="Chú thích" {...register('note')} />
              </div>
            </div>
            <div className="card mb-6">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h5 className="mb-0 card-title">Hình ảnh đơn hàng</h5>
              </div>
              <div className="card-body">
                <UploadFile files={images} onChange={onChangeFile} multiple accept='image/*'></UploadFile>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-5">
            <div className="card mb-6">
              <div className="card-header">
                <h5 className="card-title mb-0">Thông tin hàng hóa</h5>
              </div>
              <div className="card-body">
                <div className="row mb-5 gx-5">
                  <div className="col">
                    <FormFloating
                      label="Loại hàng hóa"
                      placeholder="Loại hàng hóa"
                      {...register('productType', { required: true })}
                      // isError={errors.productType ? true : false}
                      // errMsg={errors.productType?.message}
                    ></FormFloating>
                  </div>
                </div>
                <div className="row mb-5 gx-5">
                  <div className="col">
                    <FormFloating type="number" label="Số kiện" placeholder="Số kiện" {...register('numberParcel', { required: true })} isError={errors.numberParcel ? true : false} errMsg={errors.numberParcel?.message}></FormFloating>
                  </div>
                  <div className="col">
                    <FormFloating label={'Số khối (m\u00B3)'} placeholder={'Số khối (m\u00B3)'} {...register('size')} type="number"></FormFloating>
                  </div>
                </div>
                <div className="row mb-5 gx-5">
                  <div className="col">
                    <FormFloating label="Số cân (kg)" placeholder="Số cân (kg)" {...register('weight')} type="number"></FormFloating>
                  </div>
                  <div className="col">
                    <FormFloating type="number" label="Số cái" placeholder="Số cái" {...register('piece')}></FormFloating>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-6">
              <div className="card-header">
                <h5 className="card-tile mb-0">Thông tin cước phí</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12 py-3">
                    <FormFloatingNumber label="Cước vận chuyển (¥)" placeholder="Cước vận chuyển" name="shippingFee" onChange={onChangeValue} defaultValue={orderRequest.shippingFee || 0} min="0" />
                  </div>
                  <div className="col-12 py-3 border-bottom">
                    <div>Cước phụ</div>
                    <div className="ps-10 mt-2">
                      <div className="row">
                        <div className="col-sm-12 col-lg-6 py-3">
                          <FormFloatingNumber label="Cước nội địa (¥)" placeholder="Cước nội địa" name="domesticFee" onChange={onChangeValue} defaultValue={orderRequest.domesticFee || 0} min="0" />
                        </div>
                        <div className="col-sm-12 col-lg-6 py-3">
                          <FormFloatingNumber label="Kéo xe (¥)" placeholder="Kéo xe" name="towingFee" onChange={onChangeValue} defaultValue={orderRequest.towingFee || 0} min="0" />
                        </div>
                        <div className="col-sm-12 col-lg-6 py-3">
                          <FormFloatingNumber label="Xe nâng (¥)" placeholder="Xe nâng" name="forkliftFee" onChange={onChangeValue} defaultValue={orderRequest.forkliftFee || 0} min="0" />
                        </div>
                        <div className="col-sm-12 col-lg-6 py-3">
                          <FormFloatingNumber label="Đóng gỗ (¥)" placeholder="Đóng gỗ" name="woodenFee" onChange={onChangeValue} defaultValue={orderRequest.woodenFee || 0} min="0" />
                        </div>

                        <div className="col-sm-12 col-lg-6 py-3">
                          <FormFloatingNumber label="Thanh toán hộ" placeholder="Thanh toán hộ" name="payOnHehalf" onChange={onChangeValue} defaultValue={orderRequest.payOnHehalf || 0} min="0" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 d-flex justify-content-between">
                    <div className="fs-5 fw-semibold">Tổng tiền</div>
                    {orderRequest.totalFee ? (
                      <div className="text-danger fw-semibold">
                        {' '}
                        <NumberFormat value={orderRequest.totalFee}></NumberFormat> ¥
                      </div>
                    ) : (
                      <div className="text-danger fw-semibold">--</div>
                    )}
                  </div>
                  <div className="col-12 py-6">
                    <FormFloatingNumber label="Số tiền cần thanh toán (¥)" placeholder="Số tiền cần thanh toán" name="payAmount" onChange={onChangeValue} defaultValue={orderRequest.payAmount || 0} min="0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
