import { toast } from 'react-toastify';
import UploadFile from '../../../components/ui/UploadFile';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../libs/hook';
import { hideSpinner, showSpinner } from '../../../libs/features/spinnerSlice';
import { ShipmentService } from '../../../core/services/shipment.service';
import Currency from '../../../components/common/Currency';
import { userInfoSelector } from '../../../libs/selectors/userSelector';
import { RoleConstant } from '../../../core/constants/role.constant';

type SuccFormProps = {
  orderDetail: any;
  onSubmit?: (event: any) => void;
  onCancel?: (event?: any) => void;
  shipments: any[];
  // shipment: any;
};

export default function DeliveryForm({ onCancel, onSubmit, orderDetail, shipments }: SuccFormProps) {
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector(userInfoSelector);
  const [files, setFiles] = useState<any[]>([]);
  const [shipmentsUploaded, setShipmentsUploaded] = useState<any[]>([]);

  const onSubmitForm = () => {
    if (shipmentsUploaded.length < shipments.length) {
      toast.error('Chưa tải đầy đủ hình ảnh giao hàng của các đơn');
      return;
    }

    const shipmentIds = shipments.map((item) => item.id);

    let request = {
      orderId: orderDetail.orderId,
      shipmentIds: shipmentIds,
      files: files
    };
    dispatch(showSpinner());
    ShipmentService.deliveringAllShipment(request)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        if (res.data.status) {
          onSubmit?.(request);
          toast.success('Cập nhật giao hàng thành công');
        } else {
          toast.error('Đã có lỗi xảy tra trong quá trình xử lý, vui lòng thử lại');
        }
      });
  };

  const onChangeFile = (event: any, shipment: any) => {
    let fileToAddTemps = event.filesToAdd;
    let temps = files.filter((item: any) => item.shipmentId != shipment.id);
    let fileUploadTemps: any[] = [];
    fileToAddTemps.forEach((item: any, idx: any) => {
      let itemTemp: any = {
        file: item,
        newFileName: shipment.id + '_ext' + idx + '.' + getFileExtension(item.name),
        shipmentId: shipment.id
      };

      fileUploadTemps.push(itemTemp);
    });
    setFiles([...temps, ...fileUploadTemps]);

    let shipmentsUploadedTemp = shipmentsUploaded.filter((item: any) => item != shipment.id);
    if (fileToAddTemps && fileToAddTemps.length > 0) {
      shipmentsUploadedTemp = [...shipmentsUploadedTemp, [shipment.id]];
    }
    setShipmentsUploaded(shipmentsUploadedTemp);
  };

  const getFileExtension = (filename: any) => {
    return filename.split('.').pop();
  };

  return (
    <>
      <div className="modal-body">
        {shipments?.map((shipment, idx) => (
          <div className="row" key={idx}>
            <div className="col-12 py-3 d-flex">
              <div className="accordion-header border-0">
                <div role="button" className="accordion-button pe-0 shadow-none collapsed">
                  <div className="d-flex align-items-center">
                    <div className="avatar-wrapper">
                      <div className="avatar me-4">
                        <span className="avatar-initial rounded-circle bg-label-secondary">
                          <i className="ri-car-line ri-24px"></i>
                        </span>
                      </div>
                    </div>
                    <span className="d-flex flex-column fw-normal">
                      <span className="text-heading mb-1">
                        <span className="fw-semibold">{shipment.licensePlate}</span>
                        {shipment.vehicleType && <span className="fw-semibold"> - {shipment.vehicleType}</span>}
                        {shipment.borderGate && <span className="fw-semibold"> - {shipment.borderGate}</span>}
                        {shipment.address && <span className="fw-semibold"> - {shipment.address}</span>}
                      </span>
                      <div>
                        <div className="py-1 d-inline-block justify-content-between">
                          <span>Số kiện</span>
                          <span className="fw-semibold ps-3">{shipment.numberParcel}</span>
                        </div>
                        {shipment.weight > 0 && (
                          <div className="py-1 d-inline-block justify-content-between ps-5">
                            <span>Số cân</span>
                            <span className="fw-semibold ps-3">{shipment.weight ? <>{shipment.weight} kg</> : '-'}</span>
                          </div>
                        )}
                        {shipment.size > 0 && (
                          <div className="py-1 d-inline-block justify-content-between ps-5">
                            <span>Số khối</span>
                            <span className="fw-semibold ps-3">
                              {shipment.size ? (
                                <>
                                  {shipment.size} m<sup>3</sup>
                                </>
                              ) : (
                                '-'
                              )}
                            </span>
                          </div>
                        )}
                        {shipment.piece > 0 && (
                          <div className="py-1 d-inline-block justify-content-between ps-5">
                            <span>Số cái</span>
                            <span className="fw-semibold ps-3">{shipment.piece ? shipment.piece : '--'}</span>
                          </div>
                        )}
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {currentUser.role?.roleId != RoleConstant.ROLE_WHVN && currentUser.role?.roleId != RoleConstant.ROLE_CUSTOMER && shipment.shippingFee > 0 && (
              <div className="col-12">
                <div className="d-flex justify-content-between field-info">
                  <div>Phí vận chuyển</div>
                  <div>
                    <Currency price={shipment.shippingFee ? shipment.shippingFee : 0}></Currency>
                  </div>
                </div>
              </div>
            )}

            {(shipment.domesticFee > 0 || shipment.towingFee > 0 || shipment.forkliftFee > 0 || shipment.woodenFee > 0 || shipment.payOnHehalf > 0) && (
              <div className="col-12">
                <div className="py-3 border-bottom">
                  <div>Cước phụ</div>
                  <div className="ps-10 mt-2">
                    <div className="row">
                      {shipment.domesticFee > 0 && (
                        <div className="col-sm-12 col-lg-6">
                          <div className="d-flex justify-content-between field-info">
                            <div>Cước nội địa</div>
                            <div>
                              <Currency price={shipment.domesticFee ? shipment.domesticFee : 0}></Currency>
                            </div>
                          </div>
                        </div>
                      )}

                      {shipment.towingFee > 0 && (
                        <div className="col-sm-12 col-lg-6">
                          <div className="d-flex justify-content-between field-info">
                            <div>Kéo xe</div>
                            <div>
                              <Currency price={shipment.towingFee ? shipment.towingFee : 0}></Currency>
                            </div>
                          </div>
                        </div>
                      )}

                      {shipment.forkliftFee > 0 && (
                        <div className="col-sm-12 col-lg-6">
                          <div className="d-flex justify-content-between field-info">
                            <div>Xe nâng</div>
                            <div>
                              <Currency price={shipment.forkliftFee ? shipment.forkliftFee : 0}></Currency>
                            </div>
                          </div>
                        </div>
                      )}

                      {shipment.woodenFee > 0 && (
                        <div className="col-sm-12 col-lg-6">
                          <div className="d-flex justify-content-between field-info">
                            <div>Đóng gỗ</div>
                            <div>
                              <Currency price={shipment.woodenFee ? shipment.woodenFee : 0}></Currency>
                            </div>
                          </div>
                        </div>
                      )}

                      {shipment.payOnHehalf > 0 && (
                        <div className="col-sm-12 col-lg-6">
                          <div className="d-flex justify-content-between field-info">
                            <div>Thanh toán hộ</div>
                            <div>
                              <Currency price={shipment.payOnHehalf ? shipment.payOnHehalf : 0}></Currency>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {currentUser.role?.roleId != RoleConstant.ROLE_WHVN && currentUser.role?.roleId != RoleConstant.ROLE_CUSTOMER && (
                  <div className="py-3 d-flex justify-content-between field-info ">
                    <div>Tổng tiền</div>
                    <div className="fw-semibold">
                      <Currency price={orderDetail.totalFee}></Currency>
                    </div>
                  </div>
                )}
                <div className="d-flex justify-content-between field-info">
                  <div className="fs-5 fw-semibold">Số tiền cần thanh toán</div>
                  <div className="text-danger fw-semibold">
                    <Currency price={shipment.payAmount ? shipment.payAmount : 0}></Currency>
                  </div>
                </div>
              </div>
            )}

            <div className="col-12 pt-8 pb-3">
              <div className="text-heading mb-2">Hình ảnh chuyến hàng</div>
              <UploadFile accept="image/*" onChange={(event) => onChangeFile(event, shipment)} multiple></UploadFile>
            </div>
          </div>
        ))}
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-outline-secondary waves-effect" onClick={() => onCancel?.(false)}>
          Hủy
        </button>
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={onSubmitForm}>
          Xác nhận giao hàng
        </button>
      </div>
    </>
  );
}
