import { useEffect, useState } from 'react';
import { PaginationConfig } from '../../core/config/PaginationConfig';

type PaginationProps = {
  size?: number;
  totalRecords: number;
  onChange?: (event: any) => void;
};

export default function Pagination(props: PaginationProps) {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  useEffect(() => {
    let page = Math.ceil(props.totalRecords / (props.size ? props.size : PaginationConfig.PAGE_SIZE));
    setTotalPage(page);
  }, [props]);

  useEffect(() => {
    props.onChange?.(currentPage);
  }, [currentPage])

  const onNavigate = (page: any) => {
    setCurrentPage(page);
    // props.onChange?.(page);
  };

  const onNext = () => {
    if (currentPage >=  totalPage) return;
    setCurrentPage(preState => preState + 1);
  }

  const onPre = () => {
    if (currentPage <= 1 ) return;
    setCurrentPage(preState => preState - 1);
  }

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination">
        <li className="page-item first cursor-pointer">
          <a className="page-link waves-effect">
            <i className="tf-icon ri-skip-back-mini-line ri-20px"></i>
          </a>
        </li>
        <li className="page-item prev cursor-pointer" onClick={onPre}>
          <a className="page-link waves-effect">
            <i className="tf-icon ri-arrow-left-s-line ri-20px"></i>
          </a>
        </li>
        {Array.from({ length: totalPage }).map((_, index) => (
          <li key={index} className={'cursor-pointer page-item' + (currentPage == index + 1 ? ' active' : '')} onClick={() => onNavigate(index + 1)}>
            <a className="page-link waves-effect">{index + 1}</a>
          </li>
        ))}

        {/* <li className="page-item">
          <a className="page-link waves-effect">
            2
          </a>
        </li>
        <li className="page-item active">
          <a className="page-link waves-effect">
            3
          </a>
        </li>
        <li className="page-item">
          <a className="page-link waves-effect">
            4
          </a>
        </li>
        <li className="page-item">
          <a className="page-link waves-effect">
            5
          </a>
        </li> */}
        
        <li className="page-item next cursor-pointer" onClick={onNext}>
          <a className="page-link waves-effect">
            <i className="tf-icon ri-arrow-right-s-line ri-20px"></i>
          </a>
        </li>
        <li className="page-item last cursor-pointer">
          <a className="page-link waves-effect">
            <i className="tf-icon ri-skip-forward-mini-line ri-20px"></i>
          </a>
        </li>
      </ul>
    </nav>
  );
}
