import { useEffect, useState } from 'react';
import FormFloating from '../../components/ui/form/FormFloating';
import { OrderService } from '../../core/services/order.service';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useAppDispatch } from '../../libs/hook';
import { hideSpinner, showSpinner } from '../../libs/features/spinnerSlice';
import { toast } from 'react-toastify';
import { ShipmentService } from '../../core/services/shipment.service';
import NumberFormat from '../../components/common/NumberFormat';
import FormFloatingNumber from '../../components/ui/form/FormFloatingNumber';

import Currency from '../../components/common/Currency';
import FormFloatingSelect from '../../components/ui/form/FormFloatingSelect';
import { UserService } from '../../core/services/user.service';

export default function ExportTracking() {
  const { id } = useParams();
  const [params] = useSearchParams();
  const shipmentId = params.get('id');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [orderDetail, setOrderDetail] = useState<any>({});
  let [exportRequest, setExportRequest] = useState<any>({});
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [trackingExport, setTrackingExport] = useState<any>({});
  const [vehicleInfo, setVehicleInfo] = useState<any>({});
  const [sellers, setSellers] = useState<any[]>([]);
  const [isOrderExists, setIsOrderExists] = useState<boolean>(true);

  useEffect(() => {
    getOrder();
    getSellers();
  }, [id]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      getVehicleByLicensePlate(exportRequest.licensePlate);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [exportRequest.licensePlate]);

  const getOrder = () => {
    dispatch(showSpinner());
    OrderService.getOrderById(id)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        if (res.data.status) {
          setOrderDetail(res.data.responseData);
          convertData(res.data.responseData);
        } else {
          setIsOrderExists(false);
        }
      });
  };

  const trimProperties = (obj: any): any => {
    if (typeof obj !== 'object' || obj === null) {
      return obj; // Nếu không phải object, trả về chính nó
    }

    return Object.entries(obj).reduce((acc, [key, value]) => {
      acc[key] = typeof value === 'string' ? value.trim() : value;
      return acc;
    }, {} as any);
  };

  const convertData = (data: any) => {
    if (shipmentId) {
      let temp: any = data.shipments.find((item: any, idx: any) => item.id == shipmentId);
      temp.customerPhone = data.recipientPhoneNumber;
      temp.customerId = data.customerId;
      temp.sellerId = data.sellerId;
      setExportRequest({ ...temp });

      let trackingTemp = temp?.trackings[0];
      setTrackingExport({ ...trackingTemp });
    } else {
      exportRequest.customerPhone = data.recipientPhoneNumber;
      exportRequest.customerId = data.customerId;
      exportRequest.sellerId = data.sellerId;
      setExportRequest({ ...exportRequest });
    }
  };

  const onSubmitExport = () => {
    if (vehicleInfo.licensePlate && vehicleInfo.status != '09-01') {
      toast.error(`Xe ${vehicleInfo.licensePlate} đã thông quan nên không thể xuất`);
      return;
    }
    if (exportRequest.id) {
      handleEditExportShipment();
    } else {
      handleCreateExportShipment();
    }
  };

  const handleCreateExportShipment = () => {
    setSubmitted(true);
    if (validate()) return;

    exportRequest.orderId = orderDetail.orderId;
    exportRequest = trimProperties(exportRequest);
    ShipmentService.exportShipment(exportRequest)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        if (res.data.status) {
          toast.success('Xuất kho thành công');
          navigate(`/order-mgt/detail/${id}`);
          return;
        }
        toast.error(res.data.message);
      });
  };

  const handleEditExportShipment = () => {
    setSubmitted(true);
    if (validate()) return;
    exportRequest.shipmentId = exportRequest.id;
    exportRequest.orderId = orderDetail.orderId;
    exportRequest.trackId = trackingExport.trackId;

    exportRequest = trimProperties(exportRequest);
    dispatch(showSpinner());
    ShipmentService.editExportShipment(exportRequest)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        if (res.data.status) {
          toast.success('Cập nhật thành công');
          navigate(`/order-mgt/detail/${id}`);
          return;
        }
        toast.error('Đã có lỗi xảy ra trong quá trình xử lý, vui lòng thử lại');
      });
  };

  const validate = () => {
    if (!exportRequest.address || 
      !exportRequest.borderGate || 
      !exportRequest.vehicleType || 
      !exportRequest.numberParcel || 
      !exportRequest.licensePlate ||
      !exportRequest.customerId ||
      !exportRequest.sellerId || 
      !exportRequest.customerPhone
    ) {
      return true;
    }

    if (vehicleInfo.licensePlate && vehicleInfo.status != '09-01') {
      return;
    }

    return false;
  };

  const onChangeValue = (event: any) => {
    let valueTemp = event.target.value + '';

    if (event.target.name == 'licensePlate') {
      valueTemp = valueTemp.trimStart()
    }

    if (event.target.name == 'customerPhone') {
      if (valueTemp.length <= 10) {
        exportRequest[event.target.name] = valueTemp;
      }
    } else {
      exportRequest[event.target.name] = valueTemp;
    }

    const sum =
      Number(exportRequest.domesticFee || 0) + Number(exportRequest.towingFee || 0) + Number(exportRequest.forkliftFee || 0) + Number(exportRequest.shippingFee || 0) + Number(exportRequest.woodenFee || 0) + Number(exportRequest.payOnHehalf || 0);
    exportRequest.totalFee = sum;

    setExportRequest({ ...exportRequest });
  };

  const getVehicleByLicensePlate = (licensePlate: any) => {
    if(licensePlate)
    licensePlate = licensePlate.trim();
    console.log('update car')
    ShipmentService.getVehicleByLicensePlate(licensePlate).then((res) => {
      if (res.data.status) {
        setVehicleInfo({ ...res.data.responseData });
        if (res.data.responseData?.licensePlate) {
          exportRequest.borderGate = res.data.responseData?.borderGate;
          exportRequest.address = res.data.responseData?.address;
          exportRequest.driverName = res.data.responseData?.driverName;
          exportRequest.driverPhoneNumber = res.data.responseData?.driverPhoneNumber;
          exportRequest.licensePlate = res.data.responseData?.licensePlate;
          exportRequest.vehicleType = res.data.responseData?.vehicleType;
          setExportRequest({ ...exportRequest });
        }else{
          exportRequest.borderGate = '';
          exportRequest.address = '';
          exportRequest.driverName = '';
          exportRequest.driverPhoneNumber = '';
          // exportRequest.licensePlate = '';
          exportRequest.vehicleType = '';
          setExportRequest({ ...exportRequest });
        }
      }else{
        exportRequest.borderGate = '';
        exportRequest.address = '';
        exportRequest.driverName = '';
        exportRequest.driverPhoneNumber = '';
        // exportRequest.licensePlate = '';
        exportRequest.vehicleType = '';
        setExportRequest({ ...exportRequest });
      }
    });
  };

  const getSellers = async () => {
    await UserService.getAllSeller().then((res) => {
      console.log(res);
      let data = res.data;
      data.unshift({ fullName: '---', username: null });
      setSellers(data);
    });
  };

  const onChangeSeller = (event: any) => {
    exportRequest.sellerId = event.username;
    setExportRequest({ ...exportRequest });
  };

  useEffect(() => {
    if (!exportRequest.customerId) {
      exportRequest.customerPhone = '';
      setExportRequest({ ...exportRequest });
      return;
    }
    const timeout = setTimeout(() => {
      UserService.getCustomerByUserName(exportRequest.customerId).then((res) => {
        if (res.data.status) {
          let userTemp = res.data.responseData;
          exportRequest.customerPhone = userTemp.phoneNumber;
          setExportRequest({ ...exportRequest });
        } else {
          UserService.getCustomerByPhoneNumber(exportRequest.customerPhone).then((res) => {
            if (res.data.status) {
              exportRequest.customerPhone = '';
              setExportRequest({ ...exportRequest });
            }
          });
        }
      });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [exportRequest.customerId]);

  useEffect(() => {
    if (!exportRequest.customerPhone) {
      exportRequest.customerId = '';
      setExportRequest({ ...exportRequest });
      return;
    }
    const timeout = setTimeout(() => {
      UserService.getCustomerByPhoneNumber(exportRequest.customerPhone).then((res) => {
        if (res.data.status) {
          let userTemp = res.data.responseData;
          exportRequest.customerId = userTemp.username;
          setExportRequest({ ...exportRequest });
        } else {
          UserService.getCustomerByUserName(exportRequest.customerId).then((res) => {
            if (res.data.status) {
              exportRequest.customerPhone = '';
            }
          });
        }
      });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [exportRequest.customerPhone]);

  return (
    <>
      {isOrderExists && (
        <div className="app-ecommerce">
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-6 row-gap-4">
            <div className="d-flex flex-column justify-content-center">
              <h4 className="mb-1">{shipmentId ? 'Sửa đổi thông tin chuyến hàng' : 'Xuất kho'}</h4>
              {/* <p className="mb-0">Orders placed across your store</p> */}
            </div>
            <div className="d-flex align-content-center flex-wrap gap-4">
              <button type="button" className="btn btn-outline-secondary" onClick={() => navigate(`/order-mgt/detail/${id}`)}>
                Hủy
              </button>
              <button type="button" className="btn btn-primary" onClick={onSubmitExport}>
                Lưu
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="card mb-6">
                <div className="card-header">
                  <h5 className="card-tile mb-0">Thông tin chung</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <div className="py-3 d-flex justify-content-between field-info">
                        <div>Số kiện</div>
                        <div>{orderDetail.numberParcel}</div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="py-3 d-flex justify-content-between field-info">
                        <div>Loại hàng hóa</div>
                        <div>{orderDetail.productType}</div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="py-3 d-flex justify-content-between field-info">
                        <div>Số cân</div>
                        <div>{orderDetail.weight ? <>{orderDetail.weight} kg</> : '-'}</div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="py-3 d-flex justify-content-between field-info">
                        <div>Số khối</div>
                        <div>
                          {orderDetail.size ? (
                            <>
                              {orderDetail.size} m<sup>3</sup>
                            </>
                          ) : (
                            '-'
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="py-3 d-flex justify-content-between field-info">
                        <div>Số cái</div>
                        <div>{orderDetail.piece ? <NumberFormat value={orderDetail.piece}></NumberFormat> : '-'}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row gx-5 gy-5 mt-3">
                    <div className="col-6">
                      <FormFloating
                        type="text"
                        label="Mã khách hàng"
                        placeholder="Mã khách hàng"
                        isError={submitted && !exportRequest.customerId ? true : false}
                        errMsg="Mã khách hàng còn trống"
                        onChange={onChangeValue}
                        value={exportRequest.customerId || ''}
                        name="customerId"
                      ></FormFloating>
                    </div>
                    <div className="col-6">
                      <FormFloatingSelect
                        label="Nhân viên bán hàng"
                        sources={sellers}
                        bindLabel="fullName"
                        defaultValue={exportRequest.sellerId}
                        bindValue="username"
                        onChange={(event) => onChangeSeller(event)}
                        isError={submitted && !exportRequest.sellerId ? true : false}
                        errMsg="Nhân viên bán hàng còn trống"
                      ></FormFloatingSelect>
                    </div>
                    <div className="col-12">
                      <FormFloating
                        type="number"
                        label="SĐT người nhận"
                        placeholder="SĐT người nhận"
                        isError={submitted && !exportRequest.customerPhone ? true : false}
                        errMsg={'Số điện thoại còn trống'}
                        onChange={onChangeValue}
                        name="customerPhone"
                        value={exportRequest.customerPhone || ''}
                      ></FormFloating>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-6">
                <div className="card-header">
                  <h5 className="card-tile mb-0">Thông tin cước phí</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12 py-3">
                      <FormFloatingNumber label="Cước vận chuyển" placeholder="Cước vận chuyển" name="shippingFee" onChange={onChangeValue} defaultValue={exportRequest.shippingFee || 0} min="0" />
                    </div>
                    <div className="col-12 py-3 border-bottom">
                      <div>Cước phụ</div>
                      <div className="ps-10 mt-2">
                        <div className="row">
                          <div className="col-sm-12 col-lg-6 py-3">
                            <FormFloatingNumber label="Cước nội địa" placeholder="Cước nội địa" name="domesticFee" onChange={onChangeValue} defaultValue={exportRequest.domesticFee || 0} min="0" />
                          </div>
                          <div className="col-sm-12 col-lg-6 py-3">
                            <FormFloatingNumber label="Kéo xe" placeholder="Kéo xe" name="towingFee" onChange={onChangeValue} defaultValue={exportRequest.towingFee || 0} min="0" />
                          </div>
                          <div className="col-sm-12 col-lg-6 py-3">
                            <FormFloatingNumber label="Xe nâng" placeholder="Xe nâng" name="forkliftFee" onChange={onChangeValue} defaultValue={exportRequest.forkliftFee || 0} min="0" />
                          </div>
                          <div className="col-sm-12 col-lg-6 py-3">
                            <FormFloatingNumber label="Đóng gỗ" placeholder="Đóng gỗ" name="woodenFee" onChange={onChangeValue} defaultValue={exportRequest.woodenFee || 0} min="0" />
                          </div>
                          <div className="col-sm-12 col-lg-6 py-3">
                            <FormFloatingNumber label="Thanh toán hộ" placeholder="Thanh toán hộ" name="payOnHehalf" onChange={onChangeValue} defaultValue={exportRequest.payOnHehalf || 0} min="0" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 d-flex justify-content-between">
                      <div className="fs-5 fw-semibold">Tổng tiền</div>
                      {exportRequest.totalFee ? (
                        <div className="text-danger fw-semibold">
                          {' '}
                          <Currency price={exportRequest.totalFee}></Currency>
                        </div>
                      ) : (
                        <div className="text-danger fw-semibold">--</div>
                      )}
                    </div>
                    <div className="col-12 py-6">
                      <FormFloatingNumber label="Số tiền cần thanh toán" placeholder="Số tiền cần thanh toán" name="payAmount" onChange={onChangeValue} defaultValue={exportRequest.payAmount || 0} min="0" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="card mb-6">
                <div className="card-header d-flex justify-content-between align-item-center">
                  <h5 className="card-tile mb-0">Thông tin vận chuyển</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 py-3">
                      <FormFloating
                        type="text"
                        label="Biến số xe"
                        placeholder="Biến số xe"
                        name="licensePlate"
                        onChange={onChangeValue}
                        value={exportRequest.licensePlate || ''}
                        isError={submitted && (!exportRequest.licensePlate || exportRequest.licensePlate?.trim().length <= 0) ? true : false}
                        errMsg="Biến số xe còn trống"
                        disabled={shipmentId ? true : false}
                      ></FormFloating>
                    </div>
                    <div className="col-sm-12 col-lg-6 py-3">
                      <FormFloating
                        type="number"
                        label="Số kiện"
                        placeholder="Số kiện"
                        name="numberParcel"
                        onChange={onChangeValue}
                        value={exportRequest.numberParcel || ''}
                        isError={submitted && !exportRequest.numberParcel ? true : false}
                        errMsg="Số kiện còn trống"
                        min="1"
                      ></FormFloating>
                    </div>
                    <div className="col-sm-12 col-lg-6 py-3">
                      <FormFloating type="number" label="Số cân" placeholder="Số cân" name="weight" onChange={onChangeValue} value={exportRequest.weight || ''} min="0"></FormFloating>
                    </div>
                    <div className="col-sm-12 col-lg-6 py-3">
                      <FormFloating type="number" label="Số khối" placeholder="Số khối" name="size" onChange={onChangeValue} value={exportRequest.size || ''} min="0"></FormFloating>
                    </div>
                    <div className="col-sm-12 col-lg-6 py-3">
                      <FormFloating type="number" label="Số cái" placeholder="Số cái" name="piece" onChange={onChangeValue} value={exportRequest.piece || ''} min="0"></FormFloating>
                    </div>
                    <div className="col-sm-12 col-lg-6 py-3">
                      <FormFloating
                        type="text"
                        label="Cửa khẩu"
                        placeholder="Cửa khẩu"
                        name="borderGate"
                        onChange={onChangeValue}
                        value={exportRequest.borderGate || ''}
                        min="0"
                        isError={submitted && !exportRequest.borderGate ? true : false}
                        errMsg="Cửa khẩu còn trống"
                        disabled={vehicleInfo.borderGate ? true : false}
                      ></FormFloating>
                    </div>
                    <div className="col-sm-12 col-lg-6 py-3">
                      <FormFloating
                        type="text"
                        label="Loại xe"
                        placeholder="Loại xe"
                        name="vehicleType"
                        onChange={onChangeValue}
                        value={exportRequest.vehicleType || ''}
                        min="0"
                        isError={submitted && !exportRequest.vehicleType ? true : false}
                        errMsg="Loại xe còn trống"
                        disabled={vehicleInfo.vehicleType ? true : false}
                      ></FormFloating>
                    </div>
                    <div className="col-12 py-3">
                      <FormFloating
                        type="text"
                        label="Điểm đến"
                        placeholder="Điểm đến"
                        name="address"
                        maxLength={2}
                        minLength={2}
                        isError={submitted && (!exportRequest.address || exportRequest.address?.trim().length <= 0) ? true : false}
                        errMsg="Điểm đến còn trống"
                        onChange={onChangeValue}
                        value={exportRequest.address || ''}
                        disabled={vehicleInfo.address ? true : false}
                      ></FormFloating>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!isOrderExists && (
        <div className="app-ecommerce">
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-6 row-gap-4">
            <div className="d-flex flex-column justify-content-center">
              <h4 className="mb-1">Đơn hàng không tồn tại</h4>
            </div>
            <div className="d-flex align-content-center flex-wrap gap-4">
              <button type="button" className="btn btn-outline-secondary" onClick={() => navigate(`/order-mgt`)}>
                Quay lại
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
