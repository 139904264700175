import React from "react";

interface IProps {
  price: number;
  currency?: 'RMB' | 'VND'
}

const Currency: React.FC<IProps> = ({ price, currency }) => {
  const formattedPrice = Intl.NumberFormat('vi-VN').format(price);
  return (
    <>
    {/* {Intl.NumberFormat('vi-VN', {
      style: 'currency', 
      currency: 'VND' // Change this
      }).format(price)} */}
      {formattedPrice} {currency == 'RMB' ? '¥' : 'đ'}
      {/* ¥ */}
    </>
  );
}

export default Currency;