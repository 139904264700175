import FormFloating from '../../components/ui/form/FormFloating';
import FormFloatingTextArea from '../../components/ui/form/FormFloatingTextArea';
import FormFloatingSelect from '../../components/ui/form/FormFloatingSelect';
import { UserService } from '../../core/services/user.service';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../libs/hook';
import { hideSpinner, showSpinner } from '../../libs/features/spinnerSlice';
import { OrderService } from '../../core/services/order.service';
import { useNavigate } from 'react-router-dom';
import UploadFile from '../../components/ui/UploadFile';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormFloatingNumber from '../../components/ui/form/FormFloatingNumber';
import NumberFormat from '../../components/common/NumberFormat';

const schema = yup
  .object({
    orderId: yup.string().required('Mã đơn hàng là bắt buộc'),
    // customerId: yup.string().required("Khách hàng là bắt buộc"),
    // sellerId: yup.string().required("Nhân viên bán là bắt buộc"),
    // recipientPhoneNumber: yup.string().required('Số điện thoại là bắt buộc').length(10, 'Số điện thoại có gồm 10 số'),
    // recipientPhoneNumber: yup.string().length(10, 'Số điện thoại có gồm 10 số'),
    // address: yup.string().required("Điểm đến là bắt buộc").length(2, "Điểm đến chỉ gồm 2 kí tự"),
    note: yup.string(),
    // productType: yup.string().required("Loại hàng hóa là bắt buộc"),
    numberParcel: yup.string().required('Số kiện là bắt buộc')
    // size: yup.string(),
    // weight: yup.string(),
  })
  .required();

export default function AddOrder() {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm<any>({
    resolver: yupResolver(schema)
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [sellers, setSellers] = useState<any[]>([]);
  const [customers, setCustomers] = useState<any[]>([]);
  const [fileUploads, setFileUploads] = useState<any>([]);
  const [orderRequest, setOrderRequest] = useState<any>({});
  // const [customerId, setCustomerId] = useState<any>();
  // const [recipientPhoneNumber, setRecipientPhoneNumber] = useState<any>();

  useEffect(() => {
    dispatch(showSpinner());
    initData();
    setTimeout(() => {
      dispatch(hideSpinner());
    }, 500);
  }, []);

  // useEffect(() => {
  //   if (!customerId) {
  //     setValue("recipientPhoneNumber", '');
  //     setRecipientPhoneNumber('')
  //     return;
  //   }
  //   const timeout = setTimeout(() => {
  //     UserService.getCustomerByUserName(customerId).then(res => {
  //       if (res.data.status) {
  //         let userTemp = res.data.responseData;
  //         setValue("recipientPhoneNumber", userTemp.phoneNumber);
  //         clearErrors("recipientPhoneNumber");
  //         setRecipientPhoneNumber(userTemp.phoneNumber)
          
  //       } 
  //       else {
  //         UserService.getCustomerByPhoneNumber(recipientPhoneNumber).then(res => {
  //           if (res.data.status) {
  //             setValue("recipientPhoneNumber", '');
  //             setRecipientPhoneNumber('')
  //           } 
  //         })
  //       }
  //     })
  //   }, 500);

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [customerId]);

  // useEffect(() => {
  //   if (!recipientPhoneNumber) {
  //     setValue("customerId", '');
  //     setCustomerId('')
  //     return;
  //   }
  //   const timeout = setTimeout(() => {
  //     UserService.getCustomerByPhoneNumber(recipientPhoneNumber).then(res => {
  //       if (res.data.status) {
  //         let userTemp = res.data.responseData;
  //         setValue("customerId", userTemp.username);
  //         clearErrors("customerId");
  //         setCustomerId(userTemp.username)
  //       } else {
  //         UserService.getCustomerByUserName(customerId).then(res => {
  //           if (res.data.status) {
  //             setValue("recipientPhoneNumber", '');
  //             clearErrors("recipientPhoneNumber");
  //             setCustomerId('')
  //           }
  //         })
  //       }
  //     })
  //   }, 500);

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [recipientPhoneNumber]);

  const initData = async () => {
    await Promise.all([getSellers(), getCustomers()]);
  };

  const getSellers = async () => {
    await UserService.getAllSeller().then((res) => {
      let data = res.data;
      data.unshift({ fullName: '---', username: null });
      setSellers(data);
    });
  };

  const getCustomers = async () => {
    await UserService.getAllCustomer().then((res) => {
      let data = res.data;
      data.unshift({ fullName: '---', username: null });
      setCustomers(data);
    });
  };

  const onCancel = () => {
    navigate('/order-mgt');
  };

  const onSubmit = async (event: any) => {
    dispatch(showSpinner());
    // await FileService.multiFileUpload(files).then(res => {
    //   console.log(res);
    // }, error => {
    //   dispatch(hideSpinner());
    // })
    if (!fileUploads || fileUploads.length <= 0) {
      toast('Hình ảnh nhập kho còn thiếu');
      dispatch(hideSpinner());

      return;
    }

    OrderService.addOrder(event, fileUploads)
      .finally(() => {
        setTimeout(() => {
          dispatch(hideSpinner());
        }, 500);
      })
      .then((res) => {
        if (res.data.status) {
          navigate('/order-mgt');
        }
      });
  };

  // const onChangeSellerOrCustomer = (event: any, field: string) => {
  //   setValue(field, event?.username);
  //   clearErrors(field);
  // };

  const onChangeFile = (event: any) => {
    setFileUploads(event.filesToAdd);
  };

  const onChangeValue = (event: any) => {
    let valueTemp = event.target.value + "";
    setValue(event.target.name, valueTemp.trim());
    orderRequest[event.target.name] = valueTemp.trim();
    const sum = Number(orderRequest.domesticFee || 0) + Number(orderRequest.towingFee || 0) + Number(orderRequest.forkliftFee || 0) + Number(orderRequest.shippingFee || 0) + Number(orderRequest.woodenFee || 0) + Number(orderRequest.payOnHehalf || 0);
    orderRequest.totalFee = sum;

    setOrderRequest({ ...orderRequest });
  };

  // const onChangeCustomerId = (event: any) => {
  //   setCustomerId(event.target.value);
  //   setValue('customerId', event.target.value);
  //   clearErrors('customerId');
  // };

  // const onChangePhoneNumber = (event: any) => {
  //   setRecipientPhoneNumber(event.target.value);
  //   setValue('recipientPhoneNumber', event.target.value);
  //   clearErrors('recipientPhoneNumber');
  // };

  return (
    <>
      <div className="app-ecommerce">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-6 row-gap-4">
          <div className="d-flex flex-column justify-content-center">
            <h4 className="mb-1">Nhập kho</h4>
          </div>
          <div className="d-flex align-content-center flex-wrap gap-4">
            <button type="button" className="btn btn-outline-secondary" onClick={onCancel}>
              Hủy
            </button>
            <button type="button" className="btn btn-primary" onClick={handleSubmit(onSubmit)}>
              Lưu
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-7">
            <div className="card mb-6">
              <div className="card-header">
                <h5 className="card-tile mb-0">Thông tin chung</h5>
              </div>
              <div className="card-body">
                <div className="row mb-5 gx-5">
                  <div className="col">
                    <FormFloating type="text" label="Mã đơn hàng (*)" placeholder="Mã đơn hàng" {...register('orderId')} isError={errors.orderId ? true : false} errMsg={errors.orderId?.message}></FormFloating>
                  </div>
                </div>
                {/* <div className="row mb-5 gx-5">
                  <div className="col">
                    <FormFloating
                      type="text"
                      label="Mã khách hàng"
                      placeholder="Mã khách hàng"
                      // {...register('customerId')}
                      isError={errors.customerId ? true : false}
                      errMsg={errors.customerId?.message}
                      onChange={onChangeCustomerId}
                      maxLength={10}
                      value={customerId || ''}
                      name='customerId'
                    ></FormFloating>
                  </div>
                  <div className="col">
                    <FormFloatingSelect
                      label="Nhân viên bán hàng"
                      sources={sellers}
                      bindLabel="fullName"
                      defaultValue={null}
                      bindValue="username"
                      {...register('sellerId')}
                      onChange={(event) => onChangeSellerOrCustomer(event, 'sellerId')}
                    ></FormFloatingSelect>
                  </div>
                </div>

                <div className="row mb-5 gx-5">
                  <div className="col">
                    <FormFloating
                      type="number"
                      label="SĐT người nhận"
                      placeholder="SĐT người nhận"
                      // {...register('recipientPhoneNumber')}
                      // isError={errors.recipientPhoneNumber ? true : false}
                      // errMsg={errors.recipientPhoneNumber?.message}
                      maxLength={10}
                      onChange={onChangePhoneNumber}
                      name='recipientPhoneNumber'
                      value={recipientPhoneNumber || ''}
                    ></FormFloating>
                  </div>
                </div> */}

                <FormFloatingTextArea className="mb-5" label="Chú thích" placeholder="Chú thích" {...register('note')} />
              </div>
            </div>
            <div className="card mb-6">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h5 className="mb-0 card-title">Hình ảnh đơn hàng</h5>
              </div>
              <div className="card-body">
                <UploadFile onChange={onChangeFile} multiple accept='image/*'></UploadFile>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-5">
            <div className="card mb-6">
              <div className="card-header">
                <h5 className="card-title mb-0">Thông tin hàng hóa</h5>
              </div>
              <div className="card-body">
                <div className="row mb-5 gx-5">
                  <div className="col">
                    <FormFloating label="Loại hàng hóa" placeholder="Loại hàng hóa" {...register('productType')}></FormFloating>
                  </div>
                </div>
                <div className="row mb-5 gx-5">
                  <div className="col">
                    <FormFloating type="number" label="Số kiện (*)" placeholder="Số kiện" {...register('numberParcel')} isError={errors.numberParcel ? true : false} errMsg={errors.numberParcel?.message}></FormFloating>
                  </div>
                  <div className="col">
                    <FormFloating type="number" label={'Số khối (m\u00B3)'} placeholder={'Số khối (m\u00B3)'} {...register('size')}></FormFloating>
                  </div>
                </div>
                <div className="row mb-5 gx-5">
                  <div className="col">
                    <FormFloating type="number" label="Số cân (kg)" placeholder="Số cân (kg)" {...register('weight')}></FormFloating>
                  </div>
                  <div className="col">
                    <FormFloating type="number" label="Số cái" placeholder="Số cái" {...register('piece')}></FormFloating>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-6">
              <div className="card-header">
                <h5 className="card-tile mb-0">Thông tin cước phí</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12 py-3">
                    <FormFloatingNumber label="Cước vận chuyển (¥)" placeholder="Cước vận chuyển" name="shippingFee" onChange={onChangeValue} defaultValue={orderRequest.shippingFee || 0} min="0" />
                  </div>
                  <div className="col-12 py-3 border-bottom">
                    <div>Cước phụ</div>
                    <div className="ps-10 mt-2">
                      <div className="row">
                        <div className="col-sm-12 col-lg-6 py-3">
                          <FormFloatingNumber label="Cước nội địa (¥)" placeholder="Cước nội địa" name="domesticFee" onChange={onChangeValue} defaultValue={orderRequest.domesticFee || 0} min="0" />
                        </div>
                        <div className="col-sm-12 col-lg-6 py-3">
                          <FormFloatingNumber label="Kéo xe (¥)" placeholder="Kéo xe" name="towingFee" onChange={onChangeValue} defaultValue={orderRequest.towingFee || 0} min="0" />
                        </div>
                        <div className="col-sm-12 col-lg-6 py-3">
                          <FormFloatingNumber label="Xe nâng (¥)" placeholder="Xe nâng" name="forkliftFee" onChange={onChangeValue} defaultValue={orderRequest.forkliftFee || 0} min="0" />
                        </div>
                        <div className="col-sm-12 col-lg-6 py-3">
                          <FormFloatingNumber label="Đóng gỗ (¥)" placeholder="Đóng gỗ" name="woodenFee" onChange={onChangeValue} defaultValue={orderRequest.woodenFee || 0} min="0" />
                        </div>

                        <div className="col-sm-12 col-lg-6 py-3">
                          <FormFloatingNumber label="Thanh toán hộ" placeholder="Thanh toán hộ" name="payOnHehalf" onChange={onChangeValue} defaultValue={orderRequest.payOnHehalf || 0} min="0" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 d-flex justify-content-between">
                    <div className="fs-5 fw-semibold">Tổng tiền</div>
                    {orderRequest.totalFee ? (
                      <div className="text-danger fw-semibold">
                        {' '}
                        <NumberFormat value={orderRequest.totalFee}></NumberFormat> ¥
                      </div>
                    ) : (
                      <div className="text-danger fw-semibold">--</div>
                    )}
                  </div>
                  {/*<div className="col-12 py-6">*/}
                  {/*  <FormFloatingNumber label="Số tiền cần thanh toán (¥)" placeholder="Số tiền cần thanh toán" name="payAmount" onChange={onChangeValue} defaultValue={orderRequest.payAmount || 0} min="0" />*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
