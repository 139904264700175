import { forwardRef, useEffect, useId, useState } from 'react';
import Select from 'react-select';

type FormFloatingSelectProps = {
  sources: any[];
  label: string;
  bindLabel: string;
  bindValue: string;
  defaultValue?: any;
  className?: string;
  placeholder?: string;
  isError?: boolean;
  errMsg?: any;
  onChange?: (event: any) => void;
};

const FormFloatingSelect = forwardRef<HTMLInputElement, FormFloatingSelectProps>((props, ref) => {
  const inputId = useId();
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);
  const [mapOption, setMapOption] = useState<any>({});
  const [defaultSelected, setDefaultSelected] = useState<any>();

  useEffect(() => {
    if (props.sources && props.sources.length > 0) {
      let tempMap: any = {};
      let sourceTemp: any = {};
      const temps = props.sources.map((item: any) => {
        tempMap[item[props.bindValue]] = item;
        sourceTemp[item[props.bindValue]] = { label: item[props.bindLabel], value: item[props.bindValue] };
        return { label: item[props.bindLabel], value: item[props.bindValue] };
      });
      setOptions(temps);
      setMapOption(tempMap);
      if (props.defaultValue) {
        setDefaultSelected(sourceTemp[props.defaultValue]);
      } else {
        setDefaultSelected(null);
      }
    }
  }, [props.sources, props.bindLabel, props.bindValue, props.defaultValue]);

  const onChange = (event: any) => {
    setDefaultSelected(event);
    if (event) {
      props.onChange?.(mapOption[event.value]);
    } else {
      props.onChange?.(event);
    }
  };

  return (
    <div className={'form-floating form-floating-outline' + (props.className ? props.className : '')} onClick={() => setMenuIsOpen(!menuIsOpen)}>
      <Select
        className={'form-select' + (props.isError ? ' is-invalid' : '')}
        classNamePrefix="select"
        value={defaultSelected}
        isClearable={true}
        isSearchable={true}
        name="color"
        options={options}
        menuIsOpen={menuIsOpen}
        onChange={onChange}
        placeholder={props.placeholder}
      />
      <label htmlFor={inputId}>{props.label}</label>
      {props.isError && <div className="error-feedback"> {props.errMsg} </div>}
    </div>
  );
});
export default FormFloatingSelect;
